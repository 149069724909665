@font-face {
  font-family: "Sansita One";
  src: url("/font/SansitaOne.ttf") format("woff"),
    url("/font/SansitaOne.ttf") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.recall {
  width: 100vw;
  text-align: center;
  padding: 0;
  margin: 0;
  background: url("/public/source/bg.jpg") no-repeat;
  background-size: cover;
  flex-shrink: 0;
  font-size: 16px;

  .content {
    display: flex;
    justify-content: center;
    padding: 1.5625em 1.3125em;

    .middle {
      position: relative;
      border-radius: 10px;
      background: linear-gradient(
        167deg,
        #f4c2ff 0%,
        #fceef2 12.84%,
        #f9dfff 37.04%,
        #f9dfff 98.78%
      );

      .title {
        position: relative;
        color: #d009d0;
        text-align: center;
        font-family: Cabin;
        font-size: 0.53rem;
        font-style: italic;
        font-weight: 700;
        padding: 0.3rem 0.5rem;
        line-height: 0.7rem;
      }

      .mouth {
        width: 1.25em;
        position: absolute;
        right: 0.4rem;
        bottom: 0;
      }

      .text {
        color: #9ca0a8;
        text-align: center;
        font-family: Cabin;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 400;
        line-height: 0.35rem; /* 142.857% */
        margin: 0 1em;
      }

      .info {
        display: flex;
        margin: 0.5rem 1.6875em 0.5rem 1.6875em;
        position: relative;
        .heart {
          position: absolute;
          left: -1.8em;
          top: -1.5em;
        }
        .introduce {
          text-align: left;
          margin-left: 1.375em;
          position: relative;
          .name {
            color: #d009d0;
            font-family: Cabin;
            font-size: 0.4rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5em; /* 133.333% */
          }
          .interest {
            color: #333;
            font-family: Cabin;
            font-size: 0.28rem;
            font-style: normal;
            font-weight: 400;
            line-height: 0.34rem; /* 171.429% */
          }
        }
        .info-img {
          width: 2.2rem;
        }
        @media screen and (min-width: 641px) {
          /* .info-img {
            width: 6em;
          } */
        }
      }

      .private {
        position: relative;
        margin: 16px 27px 30px 28px;
        .demon {
          position: absolute;
          right: -33px;
          top: -32px;
        }
        .pri-title {
          color: #d009d0;
          text-align: justify;
          font-family: Cabin;
          font-size: 0.33rem;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 160% */
          margin-bottom: 0.4rem;
        }
        .pri-photos {
          display: flex;
          justify-content: space-between;
          gap: 0.2rem;

          .photo {
            line-height: 0.5rem;
            .smallheart {
              position: absolute;
            }
            .pri-img {
              width: 100%;
            }
            @media screen and (min-width: 641px) {
              .smallheart {
                width: 2em;
              }
            }
          }
          @media screen and (min-width: 641px) {
            /* .photo {
              width: 30%;
            } */
          }
        }
      }

      .only {
        width: 100%;
        height: 3.25em;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Cabin;
        font-size: 0.3rem;
        font-style: italic;
        font-weight: 600;
        line-height: 24px; /* 160% */
        background: #d009d0;
        opacity: 0.8;
        margin-bottom: 0.2rem;
      }

      .public {
        display: flex;
        align-items: center;
        justify-content: center;

        .pub-photos {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1em;

          .pub-img {
            display: block;
            width: 136px !important;
            margin-bottom: 0.2rem;
            margin-top: 0.2rem;
          }
        }
        @media screen and (min-width: 641px) {
          .pub-photos {
            margin: 0 2.5em;
            gap: 1.3em;

            .pub-img {
              display: block;
              width: 100% !important;
            }
          }
        }
      }

      .flirt {
        position: relative;
        margin: 26px 30px 20px 31px;

        .colorful {
          position: absolute;
          right: -38px;
          top: -40px;
        }
        .flirt-text {
          text-align: center;
          font-family: Sansita One;
          font-size: 0.5rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background: linear-gradient(91deg, #d9107f 5.25%, #a016e1 91.13%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .goole-img {
          width: 278px;
          margin: 20px 0 24px;
        }
      }

      @media screen and (min-width: 641px) {
        .title {
          padding: 0.8em;
        }
        .text {
          margin: 0 2.5em;
        }
        .info {
          margin-left: 2.5em;
          margin-right: 2.5em;
        }
        .private {
          margin-left: 2.5em;
          margin-right: 2.5em;
        }
        .public {
          margin: 0 2.5em;
        }
        .flirt {
          .flirt-img {
            width: 50%;
          }
          .goole-img {
            width: 80%;
          }
        }
      }
    }
  }

  @media screen and (min-width: 641px) {
    .middle {
      width: 45%;
    }
  }
}

a.custom-button-info {
  position: absolute;
  background: none;
  border-radius: 49.5px;
  border: 1px solid #a216df;
  padding:  0.1rem 0.3rem;
  cursor: pointer;
  color: #d009d0;
  text-align: center;
  font-family: Sansita One;
  font-size: 0.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  bottom: 0;
  transition: background 0.3s ease-in-out;
}

a.custom-button-info:hover {
  color: #fff;
  background: linear-gradient(91deg, #d9107f 5.25%, #a016e1 91.13%);
}

a.custom-button {
  display: block;
  background: none;
  border-radius: 49.5px;
  border: 1px solid #a216df;
  padding:  0.1rem 0.35rem;
  cursor: pointer;
  color: #d009d0;
  text-align: center;
  font-family: Sansita One;
  font-size: 0.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: background 0.3s ease-in-out;
}

a.custom-button:hover {
  color: #fff;
  background: linear-gradient(91deg, #d9107f 5.25%, #a016e1 91.13%);
}

.pri-but{
  font-size: 0.23rem !important;
}
