
@font-face {
    font-family: "Cabin";
    src: url("../../font/Cabin-Regular-1.ttf") format("woff"),
      url("../../font/Cabin-Regular-1.ttf") format("woff2");
    font-weight: normal;
    font-style: normal;
  }

.Qa {
    height: 100vh;
    width: 100vw;
    background-image: url("../../public/source/bg-qa.png");
    background-size: cover;
    background-position: center;
    overflow: auto;

    .nodata {
        height: 100vh;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;

        .nodata1 {
            text-align: center;

            .coming {
                color: #333;
                text-align: center;
                font-family: Cabin;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }

    .source {
        margin: 20px;
        border-radius: 16px;
        background-color: #fff;

        .item {
            // font-size: large;
            cursor: pointer;
            border-radius: 16px;
            background-color: #fff;
            line-height: 24px;

            .title {
                position: relative;
                display: flex;
                justify-content: space-between;
                padding: 12px;
                border-radius: 16px;
                background: #fff;
                font-family: auto;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                font-size: 18px;
                z-index: 1;
                font-family: Cabin;
                font-style: normal;
                font-weight: 400;
            }

            .content {
                margin: 0 3px;
                padding-left: 0;
                padding-right: 0;
                background-color: #ffeffc;
                word-wrap: break-word;
                margin: -10px 3px;
                z-index: 0;
                font-weight: 400;
                font-family: Cabin;
            }

            .content img {
                max-width: 100%;

                float: left;
                // margin: 5px 5px 10px 5px; /* 调整图片周围的外边距
                // height: auto; /* 保持图片的宽高比 */
           
            }

            .content p {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                font-family: Cabin;
            }
        }

        .item:last-child .content {
            border-radius: 0 0 16px 16px;
        }

        .item {
            -webkit-tap-highlight-color: transparent; /* Safari, Chrome, and some other browsers */
            tap-highlight-color: transparent; /* Windows Phone */
        }
    }
}

.ql-container.ql-snow{
    border: none;
}
.ql-toolbar.ql-snow{
    display: none;
}
