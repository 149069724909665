html {
    font-size: calc(100vw / 19.2);
  }
  
  #app {
    font-size: initial;
  }
  
  .App {
    height: 100vh;
    position: relative;
    display: flex;
  }
  
  .goback{
    position: fixed;
    z-index: 9;
    top: 20px;
    left: 20px;
    font-size: 20px;
    font-family: serif;
    color: #333;
  }
  .context {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 12%;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(50px);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    background-color: rgba(200, 200, 200, 0.03);
  }
  
  .bg {
    transition: all 1.2s;
    width: 40vw;
    height: 100vh;
    position: relative;
    background-size: cover;
  }
  
  .logo {
    width: 0.48rem;
    height: 0.48rem;
    margin-top: .7rem;
    background-image: url(../public/LOGO.png);
    background-size: 100% 100%;
    position: relative;
  }
  
  .meboo {
    width: 4.01rem;
    height: 0.95rem;
    margin-bottom: .38rem;
    background-image: url(../public/Meboo.png);
    background-size: 100% 100%;
  }
  
  .ifyou{
    font-size: 16px;
    width: 500px;
    line-height: normal;
  }
  .slogan {
    font-size: 0.8rem;
    font-family: Inter-SemiBold, Inter;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.97rem;
  }
  
  .carousel {
    width: 60vw;
  }
  
  .banner {
    width: 60vw;
    height: 100vh;
    background-size: cover;
    background-position: right center;
  }
  
  .download {
    display: flex;
    gap: 0.26rem;
  }
  
  .download img {
    width: 2.3rem;
    height: 0.66rem;
  }
  
  .w {
    font-size: 0.2rem;
    color: #fff;
  }
  
  .option {
    margin-top: 0.2rem;
    display: flex;
    gap: 0.3rem;
  }
  
  a {
    text-decoration: none;
    color: #333;
  }
  
  a:hover,
  a:visited,
  a:link,
  a:active {
    color: #fff;
  }
  
  .option_item {
    display: flex;
    align-items: center;
    gap: 0.04rem;
    cursor: pointer
  }
  
  .option img {
    width: .28rem;
    height: .28rem;
  }
  
  @media screen and (max-width:640px) {
    html {
      font-size: calc(100vw / 7.5);
    }
  
    .carousel {
      display: none !important;
    }
  
    .bg {
      width: 100vw !important;
      background-position: center;
    }
  
    .context {
      backdrop-filter: blur(3px);
      background-color: rgba(0, 0, 0, 0.3);
    }
  
    .ifyou{
      font-size: 16px;
      width: auto;
      line-height: normal;
    }
    .download img {
      width: 41%;
      height: auto;
    }
  
    .logo {
      width: 0.88rem;
      height: 0.88rem;
    }
  }
  .google_login{
    border: 2px solid #fff;
    width: 500px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 8px;
    text-align: center;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.08);
    z-index: 2;
  }
  .google_login::before{
    content: '';
    background-image: linear-gradient(315deg, #FFFFFF 0%, rgba(255,255,255,0.12) 100%);
    position: absolute;
    backdrop-filter: blur(9px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .g_id_signin{
    width: 100%;
  }
  .page_body{
    width: 100vw;
    height: 90vh;
    padding-top: 10vh;
    position: relative;
    overflow: hidden;
    background-image: url(../public/banner/BG_01.jpg);
    background-size: cover;
  }
  .page_body::before{
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9px);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
  }